<template>
    <main>
        <div class="container-fluid">
            <div class="page-title">
                <h1 class="page-title__title">{{DachziegelData.data.options.lang.tilelist_title}}</h1>
            </div>
            <div class="product-list">
                <ul class="row">
                    <li v-for="(item, index) in DachziegelData.data.ziegel" class="col-12">
                        <router-link class="product-teaser" :to="{ name: 'ZiegelDetail', params: {ziegelID: index }}">
                            <div class="product-teaser__image">
                                <img :src="getImgFromID(item.fields.slideshow[0].id).url" width="125">
                            </div>
                            <div class="product-teaser__info">
                                <h2 class="product-teaser__info__title">{{item.fields.headline}}</h2>
                                <p class="product-teaser__info__category">{{findZiegelTermFromTaxonomy(index)}}</p>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";

    export default {
        name: 'ZiegelListe',
        mixins: [DachziegelDataMixin],
        mounted : function () {
            this.$DKFuctions.headerShowGoBackHide()
        },
        methods: {
            getImgFromID: function (imgID) {
                return this.$DKFuctions.getImgFromID(imgID)
            },
            findZiegelTermFromTaxonomy: function (ziegelID) {
                const term = this.$DKFuctions.findZiegelTermFromTaxonomy(ziegelID, 'ziegel_kategorie');
                if (term) {
                    return term.name
                }
            }
        }
    }
</script>
